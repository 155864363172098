.barlow-thin {
    font-family: "Barlow", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .barlow-extralight {
    font-family: "Barlow", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .barlow-light {
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .barlow-regular {
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .barlow-medium {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .barlow-semibold {
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .barlow-bold {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .barlow-extrabold {
    font-family: "Barlow", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .barlow-black {
    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .barlow-thin-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .barlow-extralight-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .barlow-light-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .barlow-regular-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .barlow-medium-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .barlow-semibold-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .barlow-bold-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .barlow-extrabold-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .barlow-black-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  