.mat-mdc-form-field {

    .mat-mdc-text-field-wrapper  {
        background-color: transparent !important;
    }
    
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }
}

mat-checkbox {
    margin-left: -10px;

    .mdc-label {
        margin-bottom: 5px;
    }
}

.mat-mdc-radio-group {
    .mat-mdc-radio-button {
        .mdc-form-field {
            .mdc-label {
                margin-bottom: 0;
            }
        }
    }
}

mat-spinner {
    svg {
        circle {
            fill: transparent;
        }
    }
}

// remove 15px horizontal padding around inputs
.mdc-text-field {
    padding-left: unset !important;
    padding-right: unset !important;
}

// Remove the extra underline on text inputs, datepickers and selects when focussed.
.form-control:focus {
    box-shadow: none !important;
}

