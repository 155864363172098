/* Wendy's Style Guide Colors */

/* Todo: (ACE-3-5-2021)
    These color names don't really help when looking at a screenshot provided by Wendy and trying to guess which color it is.
    Recommend instead using a name indicative of where you would find it, like $hub-header-blue, or $chart-legend-green.
*/
$color-adriatic: #0073A8;
$color-ocean:#018899;
$color-sky: #40BDE4;
$color-pool: #01C1E3;
$color-baby: #CCEAF8;
$color-seaglass: #CCF2F6;
$color-cornflower: #7986CB;

$color-diesel: #565A5C;
$color-abby: #ABACAD;
$color-deedee: #DDDEDE;
$color-fifi: #F1F2F2;

$color-crush: #FF7851;

$color-hibiscus: #EC407A;

$color-grass: #4CAF50;

/* End of Wendy's Style Guide Colors */

/* Other colors - not currently in style guide */
$not-prod-warning: #D32F2F;
$hub-label-color: #565A5C;
$hub-bulleted-item-color: #40424A;
$hub-warning-color: #FF7043;

$hub-inactive-color: #ABACAD;

$hub-dialog-title: #165C76;
$dialog-label-color: #00838f;
$hub-medium-gray: #828282;
$hub-icon-color: #64b5f6;
