/*
    The difference between this file and hub-global.css is that this file is (a) SCSS, and (b) is factored.
    We should be slowly moving content from hub-global.css into _hub-global.scss, but only as we clean it up and factor it accordingly.
*/

@import '_colors.scss';

* {
    box-sizing: border-box;
}

strong {
    font-weight: bold !important;
}

a {
    color: $color-adriatic;
}

p {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 15px;
    color: #555;
}

/* Global Styles */
button,
.button {
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    background-color: $color-adriatic;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: .35rem 1rem;
    font-size: .8125rem;
    line-height: 1.5385rem;
    border-radius: .1875rem;
    transition: all ease-in-out .15s;
    display: inline-grid;
    grid-template-columns: 1fr;
    transition: all .3s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        color: white;
        background-color: #006595;
        font-weight: bold;
    }

    &:disabled, invalid {
        opacity: 0.65;
        cursor: default;

        &:hover {
            color: white;
            background-color: $color-adriatic;
            font-weight: 500;
        }
    }

    &.cancel-button {
        background-color: #F2F2F2;
        color: #0073A8;

        &:hover {
            color: #338fb9;
        }
    }

    &.SaveButton {

        &.invalid {
            background-color: gray;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .icon-floppy-disk {
            display: inline-block;
        }

        .icon-spinner4 {
            display: none;
        }

        .icon-checkmark4 {
            display: none;
        }

        &.saving {
            .icon-floppy-disk {
                display: none;
            }

            .icon-spinner4 {
                display: inline-block;
                animation: rotation 1s linear infinite;
            }

            .icon-checkmark4 {
                display: none;
            }
        }

        &.saved {
            .icon-floppy-disk {
                display: none;
            }

            .icon-spinner4 {
                display: none;
            }

            .icon-checkmark4 {
                display: inline-block;
            }
        }
    }

    &.hub-outline-button {
        background-color: transparent;
        color: #0073A8;
        border: 1px solid #0073A8;
        border-radius: 3px;
    }
}

.naked-button {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.5rem 0.625rem;
    font-size: .8125rem;
    line-height: 1.5385;
    border-radius: 0.1875rem;
    transition: all .15s ease-in-out; // #009688

    &:hover {
        box-shadow: 0 0 0 62.5rem rgb(0 0 0 / 8%) inset;
    }

    &.toggled-on {
        background-color: #009688;
        color: white;
    }
}


.view-section-header {
    color: $color-ocean;
}

.edit-section-header {
    color: $color-diesel;
    font-size: 16px;

}

// todo: (ace 3/4/2022) this is setup for limitless buttons.  When they're all gone, get rid of the b, strong, and use flexbox instead
.hub-icon-button {
    @extend .button;
    grid-template-columns: auto 1fr;

    b, strong {
        margin-right: 1rem;
    }

    &.mirrored {
        grid-template-columns: 1fr auto;
        padding-left: 2rem;

        b, strong {
            margin-left: unset;
        }
    }
}




/* When all the other random confirmation modals are gone, we can move this into a generic confirmation-dialog component*/
.dialog-container {
    width: 25vw;
    height: 30vh;
    margin: 10px;

    @media (max-width: 1600px) {
        width: 40vw;
        height: 40vh;
    }

    @media (max-width: 1400px) {
        width: 50vw;
        height: 45vh;
    }

    @media (max-width: 1024px) {
        width: 50vw;
        height: 50vh;
    }

    @media (max-width: 768px) {
        width: 55vw;
        height: 55vh;
    }

    position: relative;
    font-family: Roboto,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
    color: #333333;
    font-weight: 400;
    padding: 20px;

    .dialog-icon {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4rem;
        color: #00BCD4;
        border: 4px solid #00BCD4;
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        user-select: none;
    }

    .dialog-content {
        width: 100%;
        height: 100%;
        padding: 20px;
        border: 4px solid $color-seaglass;

        .dialog-header {
            .title {
                text-align: center;
                font-size: 24px;
                line-height: 28.13px;
                color: $hub-dialog-title;
            }

            margin-bottom: 26px;
        }

        .dialog-body {
            height: calc(100% - 110px);

            @media(max-width: 768px) {
                height: calc(100% - 148px);
            }

            overflow-y: auto;
            font-size: 17px;
            font-weight: 400;
            line-height: 20.75px;
            margin-bottom: 20px;
        }

        .dialog-footer {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            @media (max-width: 768px) {
                flex-direction: column;

                button {
                    margin-bottom: 10px;
                }
            }
        }
    }
}


.mat-tooltip {
  background-color: black;
  font-size: 11pt;
  overflow: visible;
  position: relative;
}

.badge-prio-strategy, .badge-other-strategy, .badge-primary {
  background-color: $color-adriatic;
  border-radius: 5px;
}

.hub-custom-scrollbar {
    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border: 0px solid transparent;
        border-radius: 0px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #999;
    }
}

.accesibility-focus:focus {
    border: 2px solid $color-cornflower;
}
