/* Todo: Kill this file */

.container-fluid {
    padding: 0;
}

/* GENERAL  v0.1*/

/* Hides items with this class when viewed on Mobile */

@media (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
}

/* ANGULAR PAGE STRUCTURE ELEMENTS STYLES*/

app-root,
app-unauthorized,
app-authorization-failed,
app-logged-out,
app-fundraising-menu,
app-project-collections,
ais-instantsearch,
app-new-portfolio,
ais-hits,
div.ais-Hits,
app-project-not-found {
    display: flex;
    flex-direction: column;
    flex: 1;
}

app-sidebar-nav {
    display: flex;
}

/* BODY */

body {
    background-color: white;
    font-size: .9125rem;
    font-weight: 400;
}

.page-content {
    background-color: #f6f6f6;
}

.sidebar-xs div.sidebar-main ul.nav-sidebar i[class^="icon-"] {
    font-size: 32px !important;
}

body.sidebar-xs div.sidebar-main ul.nav-group-sub i[class^="icon-"] {
    font-size: 16px !important;
}

.icon-4x {
    font-size: 80px;
}

/* NAVIGATION */

.navbar {
    box-shadow: none;
    z-index: 101;
}

@media (min-width: 768px) {
    .navbar-collapse {
        padding-left: 0px;
    }
}

.sidebar-expand-md.sidebar-main {
    z-index: 100;
    box-shadow: none;
}

.sidebar-user-material .sidebar-user-material-body {
    background: none;
    background-color: #4caf50;
    background-size: cover;
    /*border-top: 1px solid #FFF;*/
}

.dropdown-menu {
    margin-top: 0px;
    border-radius: 0px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.sidebar-expand-md:not(.sidebar-component) {
    border: 0;
    box-shadow: 8px 0px 17px -5px rgba(0, 0, 0, 0.08) !important
}

.sidebar-expand-md.sidebar-secondary .card-header {
    padding-top: 17px !important;
}

.height-limit-150 {
    max-height: 150px;
    overflow: hidden;
}

.height-limit-300 {
    max-height: 290px;
    overflow: hidden;
}

.height-limit-450 {
    max-height: 450px;
    overflow: hidden;
}

.sidebar-expand-md.sidebar-secondary .nav-link {
    font-weight: 400;
}

@media (min-width: 768px) {
    .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-submenu>.nav-group-sub {
        position: absolute;
        top: -50%;
        right: -270px;
        width: 270px;
        display: none;
        z-index: 1000;
        box-shadow: 14px 0px 25px -5px rgba(0, 0, 0, 0.03);
        border-radius: 0px;
        background-color: #FFF;
        border-top: 1px solid #DDD !important;
        border-bottom: 1px solid #DDD !important;
        border-right: 1px solid #DDD !important;
    }
}

/* COMPONENTS */

.progress {
    border-radius: 0 !important;
}

.badge {
    white-space: normal;
    text-align: left;
    line-height: 15px;
}

.badge-indigo {
    color: #fff;
    background-color: #7986cb;
}

.badge-brown {
    color: #fff;
    background-color: #a1887f;
}

.badge-disabled {
    color: #AAA;
    background-color: #EEE;
}

.badge-other-strategy {
    color: #fff;
    background-color: #2196f3;
}

.badge-prio-strategy {
    color: #fff;
    background-color: #7986cb;
}

/* HEADERS */


.page-header-content {
    padding-left: 10px;
    padding-top: 0px;
}

.page-title {
    padding: 10px 0 10px 0px;
    position: relative;
}

/* TYPOGRAPHY */

p.narrative {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 15px;
    color: #555;
}

.font-size-xl {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
}

/* MODALS */

div.modal img {
    padding: 5px;
}

/* CONTENT */

/* So that width of screen does not go beyond full HD */

div.hd-max-width {
    max-width: 1863px !important;
}

.cell-hover div[class^="col-"]:hover {
    background-color: rgb(0, 0, 0, 0.02);
    -webkit-animation: at-ripple 0.6s linear infinite;
    animation: at-ripple 0.6s linear infinite;
}

.inset-shadow-right {
    box-shadow: inset -23px 0px 49px -35px rgba(0, 0, 0, 0.15)
}

.inset-shadow-left {
    box-shadow: inset 23px 0px 49px -35px rgba(0, 0, 0, 0.15)
}

.sb-r {
    border-right: 1px solid #DDD !important;
}

.sb-l {
    border-left: 1px solid #DDD !important;
}

.sb-b {
    border-bottom: 1px solid #DDD !important;
}

.sb-t {
    border-top: 1px solid #DDD !important;
}

@media (max-width: 768px) {
    .sb-r {
        border-right: 1px solid transparent !important;
    }

    .sb-l {
        border-left: 1px solid transparent !important;
    }
}

#spatial-extent .custom-control {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

i.icon-calendar22.output-due-date {
    margin-right: 2%;
}

.progress-report {
    border: 1px solid #EEE;
}

.progress-report .card-header {
    background-color: #f6f6f6 !important
}

#profile-contact {
    margin-bottom: .625rem;
}

#member-name {
    padding: .5rem 0;
}

#member-details {
    display: none;
}

.icon-arrow-down12 {
    color: #4caf50;
}

.bg-grey-100 {
    background-color: #f5f5f5;
}

.node-editor.note-frame.card {
    width: inherit !important;
}

.scroll-container {
    position: relative;
    max-height: 500px;
    overflow: hidden;
}

.select2-selection__clear {
    cursor: pointer;
}

.select2-selection--multiple:before {
    content: '\e9c5';
    font-family: icomoon;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -.5rem;
    font-size: 1rem;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

/* CARDS */

.roles {
    background-color: rgba(0, 0, 0, .075);
}

.card {
    box-shadow: none;
    border-radius: 0;
}

.card-shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
    transition: box-shadow 0.25s linear;
}

.card-blank {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0px;
}

.alpha-light-bg {
    background-color: rgb(255, 255, 255, 0.8) !important;
}

.alpha-dark-bg {
    background-color: rgb(0, 0, 0, 0.08) !important;
}

.alpha-dark-bg-20 {
    background-color: rgb(0, 0, 0, 0.2) !important;
}

.alpha-dark-bg-50 {
    background-color: rgb(0, 0, 0, 0.5) !important;
}

.alpha-dark-bg-75 {
    background-color: rgb(0, 0, 0, 0.75) !important;
}

/* PORTFOLIO CARDS */

.card-record {
    border-top: 5px solid transparent;
    transition: transform 0.5s;
    border-radius: 0 !important;
}

.card-record hr {
    margin-top: 2px;
    margin-bottom: 2px;
}

.status-draft,
.status-Draft {
    border-color: #777777;
}

.status-active,
.status-Active {
    border-color: #3F51B5;
}

.status-completed,
.status-Completed {
    border-color: #4caf50;
}

.status-discontinued,
.status-Discontinued {
    border-color: #f44336;
    opacity: 0.5;
}

.status-Canceled,
.status-canceled {
    border-color: #CCCCCC;
    opacity: 0.5;
}

.status-proposed,
.status-Proposed {
    border-color: #666666;
}

.status-approved,
.status-Approved {
    border-color: #2196F3;
}

.list-view .card-record {
    border-left-width: 5px;
    border-top-width: 0px;
}


.card-record div.description {
    max-height: 85px;
    overflow: hidden;
}

.card-columns {
    column-count: 4 !important;
}

.list-view .card-columns {
    column-count: 1 !important;
}

.card-record:not(.status-Canceled):hover {
    box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.14);
    background-color: #FFF;
    transition: 500ms;
    opacity: 1 !important;
}

.card-record:hover .card-footer {
    border-bottom: 1px solid #CCC;
}

.card-record .card-body {
    border: 1px solid #EEE;
}

.card-record i.icon-bookmark2,
.card-record i.icon-pulse2 {
    position: absolute;
    right: 0px;
    top: 0px;
}

.card-record .card-body span.status {
    position: absolute;
    right: 38px;
    top: 5px;
    font-weight: 500 !important;
    font-size: 0.8125rem !important;
    text-align: right;
    color: #666;
}

.card-record:hover .card-body {
    border: 1px solid #DDD;
    background-color: #FFF;
}

.card-record .card-footer {
    border-bottom: 1px solid #DDD;
    border-top: 1px solid #EEEEEE;
    background-color: #f6f6f6;
}

.card-body button {
    position: absolute;
    right: 15px;
    bottom: 20px;
    z-index: 1001;
    display: none;
}

.card-record:hover .card-body button {
    display: block;
}

.at-ripple {
    -webkit-animation: at-ripple 0.6s linear infinite;
    animation: at-ripple 0.6s linear infinite;
}

@-webkit-keyframes at-ripple {
    0% {
        box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1), 0 0 0 0 rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1);
    }

    100% {
        box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1), 0 0 0 20px rgba(102, 102, 102, 0);
    }
}

@keyframes at-ripple {
    0% {
        box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1), 0 0 0 0 rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1);
    }

    100% {
        box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1), 0 0 0 20px rgba(102, 102, 102, 0);
    }
}

/* METRICS CARDS */

.card-metrics {
    column-count: 4 !important;
}

.card-metrics .card-header span.text-dark {
    min-height: 65px;
}

/* CREATE RECORD */

.record-types img:hover {
    -webkit-animation: at-ripple 0.6s linear infinite;
    animation: at-ripple 0.6s linear infinite;
}

/* INSTANT SEARCH */

.page-item.active .page-link {
    background-color: #4caf50;
}

.ais-search-box--input {
    padding-left: 5px !important;
}

.ais-search-box {
    width: 100% !important;
}

[class^=ais-] {
    /* font-size: 0.9125rem !important; */
}

/* TAG BADGES */

a[class*='badge-']:hover {
    color: #ffffff;
}

.badge-project {
    color: #ffffff;
    background-color: #03a9f4;
}

.badge-prio-strategy {
    color: #ffffff;
    background-color: #3f51b5;
}

.badge-project-col {
    color: #ffffff;
    background-color: #8bc34a;
}

.badge-whole-system {
    color: #ffffff;
    background-color: #8775a9;
}

.badge-local-strategy {
    color: #ffffff;
    background-color: #795548;
}

.badge-web-link {
    color: #ffffff;
    background-color: #607d8b;
}

/* CHARTS */

.chart-placeholder {
    width: 100%;
    height: 250px;
    background-color: rgba(246, 246, 246, 1);
    text-align: center;
    padding: 25px;
    border: 1px solid #DDD
}

.pie svg {
    width: 43px;
    height: 43px;
    transform: rotate(-90deg);
    background: rgb(0, 0, 0, 0.05);
    border-radius: 50%;
}

circle {
    fill: rgb(0, 0, 0, 0.05);
    stroke: #2196f3;
    stroke-width: 32;
}

/* SEARCH */

.sidebar-float-right {
    position: absolute !important;
    right: 0px;
    width: 400px;
    top: 98px;
    bottom: 50px;
    background-color: #FFF;
    overflow: hidden;
    z-index: 98;
    margin: 0px;
}

div.form-group {
    padding: 5px;
    border: 1px solid transparent;
    font-size: 0.9125rem;
    font-weight: 400;
}

#SearchPanel div.form-group .custom-control-label {
    font-size: 0.85rem;
    font-weight: 300;
}

/* SECTION LIST */

ul#listRecordMenu li a {
    font-weight: 400;
}

div.section-header {
    margin-top: 2px;
    border-bottom: 1px solid #DDD;
    background-color: #fff;
}

ul.section-list {
    list-style-type: none;
    list-style-position: unset;
    padding: 0px;
}

ul.right {
    margin-right: 0px;
    margin-left: -10px;
}

ul.left {
    margin-right: -10px;
    margin-left: 0px;
}

ul.section-list li {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
    margin-left: 0px;
    padding: 5px 5px 0px 5px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    margin-bottom: 5px;
}

#lstProgressReports li:not(:last-child) {
    border-bottom: 1px solid #DDD;
}

ul.section-list li:nth-child(1) {
    border-top: 1px solid transparent;
}

ul.section-list li.no-borders {
    border-color: transparent !important;
}

ul.section-list li.selected {
    background-color: #FFF;
}

ul.section-list li.selected-dark {
    background-color: rgba(246, 246, 246, 1);
}

ul.section-list li.selected-dark:hover {
    background-color: rgba(246, 246, 246, 1) !important;
}

ul.left li:hover {
    background-color: #ffffffb5;
    border-left: 1px solid transparent;
    margin-right: 0px;
}

ul.left li.selected {
    margin-right: -1px;
    border-top: 1px solid #DDD;
    border-left: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

ul.right li:hover {
    background-color: #ffffffb5;
    border-right: 1px solid #DDDDDDB5;
    margin-left: -1px;
}

ul.right li.selected {
    margin-left: -1px;
    border-top: 1px solid #DDD;
    border-right: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

ul.left li.selected:hover {
    background-color: #FFF !important;
}

ul.right li.selected:hover {
    background-color: #FFF !important;
}

@media (max-width: 768px) {
    ul.section-list {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    ul.section-list li.selected {
        border-right: 1px solid #DDD;
    }
}

/* SLIDE CONTAINER  */

div.slide-container {
    border: none !important;
}

div.slick-slide:focus {
    outline: none;
}

div.slide-container div.slide {
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
}

/* FORM OVERRIDES */

.form-control {
    font-size: 0.9125rem;
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .625rem 0;
    font-size: .975rem;
    line-height: 1.4286;
    border-radius: 0;
}

.select-label {
    font-weight: 500;
}

.input-group-append {
    margin-left: 0px !important;
}

/* COMPONENT OVERRIDES */

.nav-tabs-bottom .nav-item.show .nav-link,
.nav-tabs-bottom .nav-link.active {
    font-weight: bold;
}

.select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    color: #333333;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 0px;
    padding: 2px 6px;
    border-left: 2px solid #666;
}

.note-toolbar-wrapper {
    height: auto !important;
}

.note-editor {
    /* border-width: 0px; */
}

.note-frame .note-editing-area .note-editable {
    padding: 1.25rem;
    padding-top: 0.25rem !important;
    min-height: unset !important;
}

/* FULLSCREEN SEARCH OVERLAY */

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgb(168, 255, 120), rgb(120, 255, 214));
}

.overlay .icon-search4 {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 0;
    color: RGB(255, 255, 255, 0.3);
    font-size: 35rem;
}

div.search-container {
    width: 90%;
    max-width: 1280px;
    z-index: 1;
}

div.search-container .search-header {
    width: 100%;
}

div.search-header h1 {
    font-size: 3rem;
    font-weight: 300;
}

div.alpha-dark-bg-25 {
    background-color: RGB(0, 0, 0, 0.25);
}

div.alpha-dark-bg-40 {
    background-color: RGB(0, 0, 0, 0.4);
}

div.large-shadow {
    box-shadow: 0 14px 126px rgba(0, 0, 0, 0.15), 0 10px 101px rgba(0, 0, 0, 0.12);
}

.search-box input {
    font-size: 2.5rem;
    font-weight: 300;
}

.search-result ul.media-list li {
    border-bottom: 1px solid RGB(255, 255, 255, 0.3);
}

.search-result ul.media-list li:hover {
    background-color: RGB(0, 0, 0, 0.1);
}

.search-result ul.media-list li a {
    color: #FFF !important;
}

.search-result ul.media-list li a:hover {
    color: rgb(168, 255, 120) !important;
}

.search-result ul.media-list li p {
    color: #FFF !important;
}

.overlay-close {
    width: 150px;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    border: 1px solid #000;
    font-size: 14px;
    /* z-index: 0; */
    display: none;
}

.overlay-data {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    visibility: 0s 0.5s;
    transition: opacity 0.5s, visibility 0s 0.5s;
}

.overlay-open {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    z-index: 10000;
}

/* SEARCH RESULTS */

ul.search-results li {
    background-color: #FFF;
    transition: all 0.5s ease;
    padding: 10px !important;
}

ul.search-results li:hover {
    background-color: RGB(0, 0, 0, 0.03);
    border-radius: 5px;
}

em {
    font-style: normal;
    color: #000000;
}

/* RECORD INSTANCE */

.summary-date {
    display: inline-block;
}

.btn.border-2 {
    padding-top: 0;
}

.map-address {
    padding: 0 0 .5rem 0;
}

/* OUTCOME AND IR METRIC VALUE TABLES */

.metric-table {
    /* border-color: transparent!important; */
}

.metric-table>tbody {
    border: 0px !important;
}

.metric-table td {
    border-top: 0px solid transparent !important;
    border-bottom: 1px dashed #EEEEEEE !important;
}

.metric-table tr.start-value {
    background-color: #f5f5ff;
}

.metric-table tr.progress-value {
    background-color: transparent;
    border-bottom: 1px solid #EEE;
}

.metric-table tr.target-value {
    background-color: #e5ffe6
}

.metric-table td {
    vertical-align: top;
}

.metric-table td span.date-label {
    font-weight: bold;
    display: block;
}

.metric-table td .value-line {
    color: black;
}

.metric-table td .value-line.entry-unit {
    color: darkgray;
}

.metric-table td .value-line .value {
    font-weight: bold;
}

.metric-table td .value-line .unit {}

.metric-table td .metadata {
    font-weight: bold;
}

.metric-table td .attribution {
    font-weight: 500;
    margin-top: 10px;
}

.metric-table td .attribution .attribution-estimate {
    margin-left: 3px;
}

.metric-table td .attribution .attribution-estimate.non-sca {}

.metric-table td .attribution-text {
    margin-top: 10px;
    color: #666;
}

/* WIZARD FORMS */

form.wizard-form div.actions ul {
    text-align: center;
}

/* FINANCIALS EDITING */

div.finance-labels .row,
div.finance-values .row {
    margin-left: 0px;
    margin-right: 0px;
}

div.finance-labels .label .col-md-12,
div.finance-values .label .col-md-12 {
    padding-top: 5px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 4px;
}

div.line-item div.form-group {
    padding: 0px;
    border: 0px;
}

.finance-container .col-md-12 {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

div.label-column {
    display: flex;
    flex-direction: column;
    padding: 1px;
}


.group span.label {
    font-weight: 500;
    color: teal;
    font-size: 120%;
    margin-left: 5px;
}

.header span.label {
    font-weight: 500;
    color: teal;
    font-size: 110%;
    margin-left: 15px;
}

.value span.label {
    margin-left: 25px;
    margin-right: 5px;
}

div.year-column {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #FFF;
    padding: 1px;
}

div.year-column .form-group,
div.label-column .form-group {
    margin-bottom: 1px;
}

div.col-header {
    font-weight: 500;
    background-color: #EEE;
    padding: 5px;
    border-bottom: 1px solid #BBB;
}

.numeric {
    text-align: right;
}

.sum {
    font-weight: 500 !important;
    color: #3949ab;
}

.sb-top {
    border-top: 1px solid #CCC;
}

div.numeric .form-control {
    text-align: right;
    padding-right: 5px;
    background-color: white;
}

/* PEOPLE LISTS */

ul.people li.media {
    border-bottom: 1px solid #EEE;
    margin-top: 0px;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

ul.people li.media:first-of-type {
    background-color: RGBA(255, 255, 255, 0.8);
    margin-bottom: 20px;
    border-bottom: 1px solid #DDD;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.04);
}

ul.people li.media:not(:first-of-type):hover {
    background-color: RGBA(255, 255, 255, 0.5);
}


/* CSS REFACTORING SITE


			 BELOW CSS IS ADDED IN THE CSS REFACTORING EFFORT


		*/

/* VIEW PAGES */

div.view-section {
    border-left: 3px solid transparent;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-top: 10px;
}

div.view-section div.details {
    padding-left: 15px;
}

div.view-section:hover {
    border-left: 3px solid #DDD;
    background: linear-gradient(90deg, RGB(238, 238, 238, 0.3), transparent);
}

div.view-section div.links {
    background-color: transparent !important;
}

div.view-section div.links .list-group-item-action:focus,
.list-group-item-action:hover {
    color: #333;
    text-decoration: none;
    background-color: #FFF
}

/* OUTCOMES, INT RESULTS and OUTPUTS*/
div.view-section div.outcome,
div.view-section div.intresult,
div.view-section div.output {
    border-bottom: 1px solid #EEE;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
}

div.view-section div.outcome:hover,
div.view-section div.intresult:hover,
div.view-section div.output:hover {
    background-color: #FFF;
}

p.element-line span {
    margin-left: 3px;
    margin-right: 3px;
    border-bottom: 1px solid #DDD;
}

div.number {
    float: left;
    margin-right: 7px;
}


/* FINANCIAL TABLE */

div.view-section table.fin-table td,
div.view-section table.fin-table th {
    padding-right: 0.35rem !important;
    vertical-align: top;
}

div.view-section table.fin-table thead tr,
div.view-section table.fin-table tfoot tr {
    background: rgba(0, 0, 0, 0.04);
}

div.view-section table.fin-table thead tr th.insight,
div.view-section table.fin-table tfoot tr th.insight {
    background: rgba(121, 134, 203, 0.08);
}

div.view-section table.fin-table tr th:not(:first-child),
div.view-section table.fin-table tr td:not(:first-child) {
    text-align: right;
}

div.view-section table.fin-table tbody tr td:first-child {
    font-weight: bold;
}

div.view-section table.fin-table tbody tr td.future-year span {
    color: #AAA;
}

div.view-section table.fin-table tbody tr:hover {
    background: rgba(0, 0, 0, 0.02);
}

div.view-section table.fin-table td div.progress {
    margin-top: 5px;
    background-color: RGBA(0, 0, 0, 0.1);
    border-radius: 0;
    height: 7px;
}



/* HUB Error Container */

.hub-error-container {
    position: absolute;
    top: 50px;
    right: 0px;
    bottom: 50px;
    width: 350px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.95);
    border: 0px solid transparent;
    padding: 5px;
}

.hub-error-container .control-buttons {
    text-align: right;
    margin-bottom: 5px;
    margin-right: 5px;
}

.hub-error-container .control-buttons .btn-sm {
    margin-left: 5px;
    border: none;
    padding: 0.15rem 0.25rem;
    font-size: 0.7rem;
}

.hub-error-container .control-buttons .btn-sm:focus {
    box-shadow: none;
}

.hub-error-container .error-list {
    max-height: calc(100vh - 160px);
    padding-right: 8px;
    overflow-y: auto;
}

.hub-error-container .error-list .error-message .stack-trace {
    cursor: pointer;
}

/* FUNDRAISING MENU */

div.hero-image {
    background-image: URL('/assets/images/fundraising-menu/fr-hero-norway.jpg');
    background-position-y: 42%;
    min-height: 267px;
}

img.prio-icon {
    width: 36px;
    height: 36px;
    margin-right: 5px;
    display: inline;
}

div.hero-image h1.page-title {
    font-size: 3em;
    color: #FFFFFF;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

form.search-form {}

form.search-form input.font-size-lg {
    background-color: RGBA(255, 255, 255, 0.8);
    font-size: 2.5rem;
    font-weight: 300;
    padding-left: 30px;
    padding-right: 10px;
    border-radius: 5px;
}

form.search-form input.font-size-lg:focus {
    background-color: RGBA(255, 255, 255, 1);
    border-color: transparent !important;
    box-shadow: 0px 26px 26px -26px rgba(0, 0, 0, 0.4) !important;
}

form.search-form div.form-group {
    margin-bottom: 3px;
}

div.search-results div.result {
    position: relative;
    border-bottom: 1px solid #EEE;
    margin-right: -1px;
    border-right: 1px solid #EEE;
}

div.search-results div.result:hover {
    background-color: #fafafa;
    margin-right: -1px;
    z-index: 999;
    border-right: 1px solid #f5f5f5;
    cursor: pointer;
}

div.search-results div.selected {
    background-color: #f5f5f5;
    border-right: 1px solid #f5f5f5;
}

div.search-results div.selected:hover {
    background-color: #f5f5f5 !important;
    border-right: 1px solid #f5f5f5;
}

.div.search-results div.result:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(245, 245, 245, 0);
    border-left-color: #f5f5f5;
    border-width: 30px;
    margin-top: -30px;
    z-index: 100000;
}

div.results .progress {
    height: 0.5rem;
}

.mark,
mark {
    padding: .25rem .5rem;
    background-color: #fbff1e;
}

#UsersDetails i {
    width: 22px;
}

/* MAPS*/

#map {
    height: calc(100vh - 200px);
    margin-right: 0px;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
