/* You can add global styles to this file, and also import other style files */

@import "./icons/icomoon/styles.min.css";
@import "./limitless/bootstrap.min.css";
@import "./limitless/bootstrap_limitless.css";
@import "./limitless/layout.min.css";
@import "./limitless/components.min.css";
@import "./limitless/colors.min.css";
@import "./custom/hub-global.css";

@import "./custom/new-styles.scss";
@import "./custom/_hub-global.scss";
@import "./_fonts.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./icons/material/material-icons.css";
@import "./_colors.scss";

@import "./custom/hub-material-overrides";
