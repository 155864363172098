@import './_colors.scss';

/* The color variables below are old. Don't use them... use the ones listed above. */
$success: $color-grass;
$indigo: #3f51b5;
$grey-600: #666;
$danger: #f44336;
$primary: #2196f3;

/* 02/20/20.  Oleg Yena.  */
.mat-slide-toggle.mat-checked:not(.mat-disabled) {

    .mat-slide-toggle-bar,
    .mat-ripple-element {
        background-color: $primary;
    }

    .mat-slide-toggle-thumb {
        background-color: white;
    }
}

/* 02/05/20.  Oleg Yena.  */
.card.card-record.card-shadow {

    &.status-active,
    &.status-Active {
        border-color: $color-adriatic;
    }

    &.status-approved,
    &.status-Approved {
        border-color: $color-adriatic;
    }

    &.status-completed,
    &.status-Completed {
        border-color: $color-adriatic;
    }

    &.status-discontinued,
    &.status-Discontinued {
        border-color: $color-adriatic;
    }

    &.status-proposed,
    &.status-Proposed {
        border-color: $color-adriatic;
    }
}

/* 9/18/19.  Konstantin Vakhrushev.  Team Member Dialog.   */
/* !important is for overriding components' style if all style is global we don't need it */
.hub-team-member-dialog .hub-role-list .checkbox-container.disabled {
    color: #999 !important;
}

.hub-team-member-dialog .hub-role-list .checkbox-container input[disabled]~.checkmark {
    background-color: #f5f5f5 !important;
    cursor: auto;
}

/* 9/24/19.  Konstantin Vakhrushev.  user profile picture.   */
app-user-profile div.avatar-dummy {
    height: 160px;
}

app-team-container {
    img.round {
        padding: 0
    }

    img.btn-success {
        border: none;
        background: none;
    }
}

/* 9/20/2019  Aaron Edwards.  Portfolio Component */
#ViewModeSelector {
    .btn {
        background-color: transparent;
        // UI may feel more natural if we don't do this
    }
}

.cardsView {
    .card-body button {
        position: absolute;
        right: 15px;
        bottom: 20px;
        z-index: 98;
        display: none;
    }
}

/* 9/20/19.  Konstantin Vakhrushev.  Portfolio page.   */
.card.card-record.chosen .card-body button {
    display: block;
}

/* 9/20/19.  Oleg Yena.  ModalWarningComponent.   */
.hub-warning-modal {
    .modal-header {
        justify-content: center;
    }

    .modal-body .content {
        .swal2-title {
            font-size: 1.0625rem;
            font-weight: 500;
            line-height: 1.5385;
            word-wrap: break-word;
        }
    }

    .modal-footer {
        justify-content: center;
    }
}

/* 11/15/19.  Oleg Yena.  FundraisingMenuComponent.   */
app-fundraising-menu {
    .bggradient-whitesmoke-white {
        background: linear-gradient(to right, whitesmoke 0%, white 100%);
    }

    .hub-custom-scrollbar {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

/* 9/20/19.  Konstantin Vakhrushev.  ProjectEditComponent.   */

.hub-close-page-modal {
    .modal-header {
        justify-content: center;
    }

    .modal-body .content {
        text-align: center;

        .swal2-title {
            font-size: 1.0625rem;
            font-weight: 500;
            line-height: 1.5385;
            text-align: center;
            position: relative;
            word-wrap: break-word;
            margin-top: .3125rem;
            margin-bottom: 0;
        }
    }

    .modal-footer {
        justify-content: center;
    }

    .btn-light {
        margin-left: 0.635rem;
    }
}

/* 26/09/2019 Konstantinv Vakhrushev Global styles */
app-portfolio .ais-ClearRefinements-button {
    display: inline;
    position: static;
}

/* 1/10/2019 Konstantin Vakhrushev name-suggestions component */
app-name-suggestions {
    .ais-SearchBox-input {
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-bottom-color: #ced4da;
        border-top-color: #eee;
        padding-left: 0;
        font-size: 0.9125rem;
    }

    .ais-SearchBox-form {

        button {
            display: none;
        }
    }

    .chosen {
        background-color: #cccc;
    }

    .ng-select {
        width: 100%;

        .ng-select-container {
            border-width: 0 0 1px 0;
            border-radius: 0;
            height: 39px;
        }
    }

    ais-hits {
        display: block;
        position: relative;
    }

    .ais-Hits {
        position: absolute;
        background: #fff;
        z-index: 1;
        padding: 1rem;
        border: 0 solid rgba(0, 0, 0, 0.15);
        border-radius: 0.1875rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        width: 100%;
        max-height: 11.1rem;
        overflow: auto;
    }
}

/* 10/1/2019 Aaron Edwards Home (spotlight projects)*/
#SlideSpotLightProjects {

    .card-description {
        height: 60px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 5px;
    }
}

/* 10/7/2019 Aaron Edwards Hub-Custom-Checkbox (Global) */
.hub-checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14.6px;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.hub-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 0px solid transparent;
    border-radius: 3px;
    background-color: #eee;
}

.hub-checkbox-container:hover input~.hub-checkmark {
    background-color: #ccc;
    transition: background-color .3s ease;
}

.hub-checkbox-container input:checked~.hub-checkmark {
    background-color: #455a64;
    transition: background-color .3s ease;
}

.hub-checkmark:after {
    content: "";
    position: absolute;
    display: none;
    opacity: 0;
}

.hub-checkbox-container input:checked~.hub-checkmark:after {
    display: block;
    opacity: 1;
}

.hub-checkbox-container .hub-checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: opacity 0.3s ease-in;
}

// 10/8/2019. Aaron Edwars. am charts CSS Animatons (moved from global)
.amcharts-g2 {
    stroke-dasharray: 3px 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -31px;
    }
}

@keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -31px;
    }
}

.amcharts-graph-column-front {
    -webkit-transition: all .3s .3s ease-out;
    transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.amcharts-g3 {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 500%;
    -webkit-animation: am-draw 20s;
    animation: am-draw 10s ease-in;
}

.amcharts-g4 {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 500%;
    -webkit-animation: am-draw 20s;
    animation: am-draw 10s ease-in;
}

@-webkit-keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }

    100% {
        stroke-dashoffset: 0%;
    }
}

@keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }

    100% {
        stroke-dashoffset: 0%;
    }
}

/* 4/10/2019 Konstantin Vakhrushev outputs component */
app-output-detail .detail-panel {
    background: #f6f6f6;
}

/* 7/10/2019 Alex Solonets project edit outputs/outcomes/intermediate-results components */
.project-edit-full-size {
    width: 100%;
}

.project-edit-left-side {
    flex: 0 0 50%;
    max-height: calc(100vh - 256px);
    overflow-x: hidden;
    overflow-y: auto;
}

.project-edit-items-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.project-edit-items-details {
    // min-height: 540px;
    background-color: #f6f6f6;
}

/* 8/10/2019 Konstantin Vakhrushev financials panel component */
app-financials-panel .hub-value-column .form-group.row.label .col-md-12 {
    display: flex;
    align-items: center;
    justify-content: center;
}

app-financials-panel .hub-label-column .form-group.row.label .col-md-12 {
    display: flex;
    align-items: center;
}

#acc-quick-links {
    .ql-limited {
        max-height: 360px;
        overflow-y: auto;
    }

    .ql-caption-item {
        cursor: pointer;

        &:hover {
            opacity: .75;
        }
    }

    .ql-empty-message {
        margin-left: 25px;
    }

    .ql-card-header {
        padding: 0.75rem 0.75rem !important;
        margin-bottom: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
}

// 12/31/2019 Leon van Gurp. Refactored ApplyModalButton into Save Button

.SaveButton {

    &.invalid {
        background-color: gray;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .icon-floppy-disk {
        display: block;
    }

    .icon-spinner4 {
        display: none;
    }

    .icon-checkmark4 {
        display: none;
    }

    &.saving {
        .icon-floppy-disk {
            display: none;
        }

        .icon-spinner4 {
            display: block;
            animation: rotation 1s linear infinite;
        }

        .icon-checkmark4 {
            display: none;
        }
    }

    &.saved {
        .icon-floppy-disk {
            display: none;
        }

        .icon-spinner4 {
            display: none;
        }

        .icon-checkmark4 {
            display: block;
        }
    }
}

/* 10/16/19.  Alex Solonets.  Project edit.   */
.applyModalButton {
    position: relative;
    height: 37px;

    .textContent {
        display: flex;
        flex-direction: row;
        justify-items: flex-end;
    }

    .textContentIcons {
        position: relative;
        width: 20px
    }

    &.invalid {
        background-color: gray;
    }

    .save-icon-props {
        position: absolute;
        height: 16px;
        width: 16px;
        transition: opacity 0.75s ease;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .icon-floppy-disk {
        @extend .save-icon-props;
        opacity: 1;
    }

    .icon-spinner4 {
        @extend .save-icon-props;
        animation: spin 2s linear infinite;
        opacity: 0;
    }

    .icon-checkmark4 {
        @extend .save-icon-props;
        opacity: 0;
    }

    &.saving {
        .icon-floppy-disk {
            opacity: 0;
        }

        .icon-spinner4 {
            opacity: 1;
        }

        .icon-checkmark4 {
            opacity: 0;
        }
    }

    &.saved {
        .icon-floppy-disk {
            opacity: 0;
        }

        .icon-spinner4 {
            opacity: 0;
        }

        .icon-checkmark4 {
            opacity: 1;
        }
    }
}


/* 10/28/2019 Aaron Edwards Material-Select (dropdown) styles */

.hub-select-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .clear-all-button {
        margin-left: -30px;
        font-size: 12pt;
        font-family: sans-serif;
        font-weight: bold;
        color: darkgray;
        cursor: pointer;
    }
}


.mat-form-field-flex {
    padding-top: 0 !important;
}

.mat-form-field-infix {
    padding-top: 11px !important;
    border-top: none;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 99999 !important;
}

/* 11/1/2019 Aaron Edwards blue outline on focus is a user agent property.  Unset it globally: */
*:focus {
    outline: none;
}

/* 11/3/2019 Aaron Edwards Restore hub-validation-error-message style */
.hub-validation-error-message {
    color: red;
    font-size: 9pt;
}

/* 24/10/2019 Konstantin Vakhrushev attributes equity indicators component */
app-attributes {

    /* 04/02/20.  Oleg Yena.  */
    .custom-checkbox>.custom-control-label::after {
        border: 2px solid #455a64;
        border-radius: .2rem;
    }

    .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
        background-color: #eee !important;
    }

    .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
        background-image: none;
    }

    .nav-tabs-vertical {
        list-style-type: none;
    }

    #equityindicators {
        .tab-content {
            flex-grow: 1;
        }

        .nav-link {
            padding: 0.8rem 1rem;
            border-color: #dee2e6 !important;
            border-right: 1px solid;
            font-weight: 300;

            &.active {
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: none;
                font-weight: inherit;
            }
        }

        .cat-title {
            color: #333;
            margin-top: 10px;
            font-weight: 300;
        }

        ::placeholder {}

        .tooltip-inner {
            max-width: 400px;
        }

        [data-hover] {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
        }
    }
}

/* 11/14/2019 Aaron Edwards All Material Form fields come with too much padding (it's part of their whole Material design thing */

mat-form-field {
    margin-bottom: -15px;
}

/* 11/25/2019 Konstantin Vakhrushev collection description overflow */
.collection-card .media-body p {
    overflow: auto;
}

/* 12/04/2019 Konstantin Vakhrushev user profile avatar */
/* USER PROFILE */
app-user-profile {

    @media (max-width: 1200px) {
        .avatar {
            bottom: auto;
        }
    }
}

/* 6/12/2019 Konstantin Vakhrushev equity indicators fixed width*/
app-attributes #equityindicators {
    .nav-item {
        width: 300px;
    }
}

/* 11/12/2019 Konstantin Vakhrushev home max width*/
app-home {
    .max-width {
        width: 100%;
        max-width: 260px;
    }
}

/* 10/12/2019 Konstantin Vakhrushev portfolio pagination*/
app-portfolio {
    ul.pagination {
        overflow: auto;

        li.page-item:first-child .page-link {
            border-radius: 0;
        }

        li.page-item:last-child .page-link {
            border-radius: 0;
        }
    }

    .minWidthNextPrev {
        min-width: 6rem;

        &:disabled {
            color: darkgray;
        }
    }
}

/* 9/12/2019 Konstantin Vakhrushev added loading indicators*/
app-project-edit,
app-project-detail,
app-business-unit {
    position: relative;

    .editLoading.applyModalButton {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 26px;
        width: 26px;
        z-index: 1000;

        .icon-spinner4 {
            opacity: 1;
            font-size: 26px;
            height: 26px;
        }
    }
}



/* 17/12/2019 Konstantin Vakhrushev outcomes chart x-axis */
@media (max-width: 1650px) {
    .amcharts-AxisRendererX-group .amcharts-AxisLabel {
        font-size: 12px;
    }
}

@media (max-width: 1470px) {
    .amcharts-AxisRendererX-group .amcharts-AxisLabel {
        font-size: 10px;
    }
}

@media (max-width: 1300px) {
    .amcharts-AxisRendererX-group .amcharts-AxisLabel {
        font-size: 0.5vw;
    }
}

/* 02/01/2020 Konstantin Vakhrushev project view relations overlap */
app-relations-container {
    .badge {
        white-space: normal;
    }
}

.badge-adriatic {
    color: #fff;
    background-color: $color-adriatic;
    border-radius: 5px;
}

.badge-ocean {
    background-color: white;
    color: $color-adriatic;
    outline: solid 2px $color-adriatic;
    border-radius: 5px;
    outline-offset: -2px;
}

/*1/4/2020 ACE Using Angular Material tooltip in Primary POC dropdown.  This styles it similar to other tooltips */
.hub-mat-tooltip {
    font-size: 14px;
    color: white;
    background-color: black;
}

.ais-RefinementList-checkbox {
    margin-right: 5px;
    vertical-align: -1px;
}

.ais-RefinementList-count {
    margin-left: 5px;
}


/* 02/14/2020 Konstantin Vakhrushev collections link color and font size */
app-your-collections .p-2 .list-group-item {
    color: #333;
    font-size: 0.875rem;
}

/* 03/27/2020 Konstantin Vakhrushev mat dialog */
.cdk-overlay-backdrop,
.cdk-overlay-container {
    z-index: 99999 !important;

    .mat-mdc-dialog-container {
        overflow: visible;
    }
}

.cdk-overlay-connected-position-bounding-box {
    z-index: 99999 !important;
}

// 02/03/2022 Matthew Bosek Record Description font size and line-height
// 02/04/2022 Matthew Bosek For nested <p> elements in the ck editors
.record-description {
    resize: none;

    .ck > p {
        font-size: 16px;
        line-height: 20px;
        word-break: break-word;
        p {
            font-size: 16px;
            line-height: 20px;
            word-break: break-word;
        }
    }
}


.show-hide-button {
    position: absolute;
    left: -72px;
    top: 125px;
    width: 180px;
    height: 25px;
    background-color: #EDEDED;
    border-radius: 20px 20px 0 0;
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: $color-adriatic;
        font-weight: 500;
    }
}
